<template>
  <div>
    <el-image class="banner" :src="require('@as/serve/banner.png')"></el-image>
    <div class="menu">
      <div class="radio_group">
        <div class="label">领域</div>
        <el-radio-group v-model="radio1">
          <el-radio-button label="全部"></el-radio-button>
          <el-radio-button
            :label="'上海' + index"
            v-for="(item, index) in 20"
            v-bind:key="item"
          ></el-radio-button>
        </el-radio-group>
      </div>
      <div class="radio_group">
        <div class="label">地点</div>
        <el-radio-group v-model="radio2">
          <el-radio-button label="全部"></el-radio-button>
          <el-radio-button
            :label="'广州' + index"
            v-for="(item, index) in 10"
            v-bind:key="item"
          ></el-radio-button>
        </el-radio-group>
      </div>
    </div>
    <div class="member_list">
      <div class="card_container">
        <div
          class="card_small"
          v-for="item in 18"
          v-bind:key="item"
          @click="toDetail()"
        >
          <el-image class="img" :src="require('@as/serve/card.png')"></el-image>
          <div class="info">
            <div class="name">李青峰</div>
            <div class="post">新浪微博 运营总监</div>
          </div>
        </div>
      </div>
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio1: 1,
      radio2: 1,
    }
  },
  methods: {
    toDetail() {
      this.$router.push('memberDetail')
    },
  },
}
</script>

<style lang="less" scoped>
.banner {
  display: block;
}
.menu {
  padding: 23px 0;
  background: #363636;
  .radio_group {
    margin: 0 auto 40px;
    display: flex;
    align-items: baseline;
    width: 1200px;
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      margin-right: 36px;
      flex-shrink: 0;
      font-size: 14px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #a38f5d;
    }
    & /deep/ .el-radio-button__inner {
      margin: 0 30px 14px 0;
      padding: 8px 16px;
      font-size: 14px;
      color: #cccccc;
      background: none;
      border: 1px solid transparent;
      box-sizing: border-box;
    }
    & /deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      padding: 8px 16px;
      color: #a38f5d;
      background: #1d2029;
      border: 1px solid #a38f5d;
      border-radius: 15px;
      box-shadow: none;
      box-sizing: border-box;
    }
  }
}
.member_list {
  padding: 40px 0;

  background: #1f1f1f;
  .card_container {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
  }
  .card_small {
    margin-right: 105px;
    margin-bottom: 75px;
    display: flex;
    height: 145px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
    .img {
      width: 145px;
      height: 145px;
      display: block;
    }
    .info {
      padding: 30px 0 30px 16px;
      width: 165px;
      height: 145px;
      text-align: left;
      background: #2a2a2a;
      box-sizing: border-box;
      .name {
        font-size: 24px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #a38f5d;
      }
      .post {
        margin-top: 16px;
        font-size: 14px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #cccccc;
        opacity: 0.87;
      }
    }
  }
  & /deep/ .el-pagination {
    margin: 55px 0 0;

    font-size: 14px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    color: #a38f5d;
    text-align: center;
  }
  & /deep/ .el-pagination.is-background .btn-prev:disabled {
    color: #a38f5d;
  }
  & /deep/ .el-pagination.is-background .btn-prev {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #a38f5d;
    background: transparent;
    border: 1px solid #a38f5d;
  }
  & /deep/ .el-pagination.is-background .btn-next:disabled {
    color: #a38f5d;
  }
  & /deep/ .el-pagination.is-background .btn-next {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #a38f5d;
    background: transparent;
    border: 1px solid #a38f5d;
  }

  & /deep/ .el-pagination.is-background .el-pager li {
    color: #a38f5d;
    border: 1px solid #a38f5d;
    border-radius: 4px;
    background: transparent;
  }
  & /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
    color: #282828;
    background: #a38f5d;
  }
  & /deep/ .el-pager li {
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
  }
}
</style>